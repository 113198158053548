import { makeStyles } from '@material-ui/core/styles';
import themeDefault from '../../themeDefault';

const yellowColor = themeDefault.primaryBackgroundColor;

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    marginTop: '1rem',
    padding: '1rem 1rem 0 1rem',
  },
  form: {
    marginTop: theme.spacing(2)
  },
  media: {
    width: '100%',
    marginBottom: '8px'
  },
  buttonStyle: {
    margin: theme.spacing(1),
    minWidth: '150px',
    cursor: 'pointer',
  },
  buttonAccept: {
    color: 'green',
    borderColor: 'green',
  },
  buttonDecline: {
    color: 'red',
    borderColor: 'red',
  },
  buttonCancelCounterOffer: {
    color: themeDefault.darkGreyColor,
    borderColor: themeDefault.darkGreyColor,
  },
  buttonCounterOffer: {
    color: yellowColor,
    borderColor: yellowColor,
  },
  buttonCounterOfferLarge: {
    width: '318px'
  },
  text: {
    fontSize: '1rem',
    color: 'black',
    lineHeight: '1.2rem',
    fontWeight: '400',
  },
  countdown: {
    marginTop: '1rem',
    color: '#FFC200',
  },
  countdownNumber: {
    fontSize: '4rem',
    fontWeight: '600',
  },
  countdownText: {
    fontSize: '1.1rem',
    fontWeight: '600',
  },
  divider: {
    padding: '0 .5rem',
  },
  divider2: {
    padding: '0 1.2rem',
  },
  textRegards: {
    marginTop: '1rem',
    fontStyle: 'italic',
  },
  inputField: {
    margin: theme.spacing(1),
    marginBottom: '0',
    '&>div': {
      borderColor: yellowColor,
      maxHeight: '41px',
    },
    '& input': {
      top: '0.3rem',
    },
    '& > div > div > div > div': {
      top: '12px',
      fontSize: '12px',
      backgroundColor: 'initial'
    }
  },
  selectField: {
    margin: theme.spacing(1),
    marginBottom: '0',
    '& > div > div > div': {
      borderColor: yellowColor,
      maxHeight: '42.7px',
    },
    '& > div > div > div > div': {
      top: '6px'
    }
  },
  textareaField: {
    margin: theme.spacing(1),
    marginBottom: '0',
    '&>div': {
      borderColor: yellowColor,
      maxHeight: '123px',
    },
    '& textarea': {
      top: '4px',
      padding: '0 0 0 10px',
      width: '98%',
      maxHeight: '112px',
    },
    '& > div > div > div > div': {
      top: '12px',
      fontSize: '12px',
      backgroundColor: 'initial'
    }
  },
  headCompName: {
    display: 'inline-block',
    marginBottom: '4px',
    padding: '8px 24px',
    border: `solid 1px ${yellowColor}`,
    borderRadius: '5px',
    fontWeight: '600'
  },
  formHeader: {
    fontSize: '16px',
    fontWeight: '700',
    margin: '0',
    marginBottom: theme.spacing(3),
  }
}));