import React from 'react';

import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { InputField, NoteInputField, FormSelectFilter } from 'components';
// eslint-disable-next-line no-unused-vars
import { formatNumber, arrayOfTerms, milesPerYearArray, } from 'helpers';
import { useStyles } from '../style';
import FormButtons from './FormButtons';

const LeaseForm = ({ onCounterOffer, onCancel }) => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'error']);

  const validationSchema = Yup.object().shape({
    downPayment: Yup.number()
      .required(t('required'))
      .min(0, t('downPaymentMinValidation'))
      .max(1000000000, t('downPaymentMaxValidation'))
      .typeError(t('pleaseEnterNumber')),
    monthlyPayment: Yup.number()
      .required(t('required'))
      .min(0, t('monthlyPaymentMinValidation'))
      .max(1000000000, t('monthlyPaymentMaxValidation'))
      .typeError(t('pleaseEnterNumber')),
    term: Yup.number()
      .required(t('required'))
      .min(0, t('termMinValidation'))
      .max(1000000000, t('termMaxValidation'))
      .typeError(t('pleaseEnterNumber')),
    milesPerYear: Yup.number()
      .required(t('required'))
      .min(0, t('milesPerYearMinValidation'))
      .max(1000000000, t('milesPerYearMaxValidation'))
      .typeError(t('pleaseEnterNumber')),
    internalNotes: Yup.string()
      .max(1000, t('notesValidation'))
  });

  return (
    <Formik
      initialValues={{
        downPayment: undefined,
        monthlyPayment: undefined,
        term: undefined,
        milesPerYear: undefined,
        internalNotes: undefined,
      }}
      validationSchema={validationSchema}
      onSubmit={onCounterOffer}>
      {({ isSubmitting, values, setFieldValue, dirty }) => (
        <Form className={classes.form}>
          <Grid container
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={0}>
            <p className={classes.formHeader}>Provide your Counter offer details:</p>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <InputField label='downPayment$' inputName='downPayment' type='text' defaultValue={values.downPayment}
                isNumber isMoney isRequired
                className={classes.inputField}
                onChange={(e) => {
                  setFieldValue('downPayment', formatNumber(e.target.value));
                }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField label='monthlyPayment$' inputName='monthlyPayment' type='text' defaultValue={values.monthlyPayment}
                isNumber isMoney isRequired
                className={classes.inputField}
                onChange={(e) => {
                  setFieldValue('monthlyPayment', formatNumber(e.target.value));
                }} />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <FormSelectFilter
                isRequired
                label={t('term')}
                className={classes.selectField}
                onChange={(e) => {
                  setFieldValue('term', Number(e.target.value));
                }}
                value={values.term}
                menuItem={arrayOfTerms} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormSelectFilter
                isRequired
                label={t('milesPerYear')}
                className={classes.selectField}
                onChange={(e) => {
                  setFieldValue('milesPerYear', Number(e.target.value));
                }}
                value={values.milesPerYear}
                menuItem={milesPerYearArray} />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <NoteInputField label='notes' inputName='internalNotes' type='text' className={classes.textareaField} />
            </Grid>
          </Grid>
          <FormButtons disabled={isSubmitting || !dirty} onCancel={onCancel} />
        </Form>
      )}
    </Formik>
  );
};

LeaseForm.propTypes = {
  onCounterOffer: PropTypes.func,
  onCancel: PropTypes.func,
};

export default LeaseForm;
