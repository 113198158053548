import React, { useState } from 'react';

import { Grid, Card, CardActions, CardContent, CardMedia, Button, Typography } from '@material-ui/core';
import { Check, Clear } from '@material-ui/icons';
import Countdown from 'react-countdown';
import PropTypes from 'prop-types';
import { LeaseForm, FinanceForm, FinanceTradeInForm, CashForm, CashTradeInForm } from './CounterOfferForms';
import { ShareIcon } from '../../resources';
import { useStyles } from './style';

const OfferCard = ({ paymentType, isTradeIn, gifUrl, gifCounteredUrl, expireAt, clientName, salesName, companyName, onAccept, onDecline, onCounterOffer, clientSelection, isCounterOfferSelected, disclaimer }) => {
  const classes = useStyles();
  const [isExpired, setIsExpired] = useState(false);
  const [showCounterofferForm, setShowCounterofferForm] = useState(isCounterOfferSelected ?? false);

  const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
    if (hours > 0) {
      minutes = minutes + hours * 60;
    }
    if (completed) {
      setIsExpired(true);
      return <></>;
    } else {
      return (
        <Typography className={classes.countdown} align='center' component='div'>
          <Typography align='center' className={classes.text} gutterBottom variant='h6'>
            Your offer expires in:
          </Typography>
          <Typography className={classes.countdownNumber} align='center' variant='h2'>
            <span>{minutes < 10 ? `0${minutes}` : minutes}</span>
            <span className={classes.divider}>:</span>
            <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
          </Typography>
          <Typography className={classes.countdownText} align='center' variant='h2'>
            <span>minutes</span>
            <span className={classes.divider2}></span>
            <span>seconds</span>
          </Typography>
        </Typography>
      );
    }
  };

  const getCardButtons = () => {
    if (!isExpired && clientSelection === 0 && !showCounterofferForm) {
      return (
        <CardActions>
          <Grid container
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={0}>
            <Button
              variant='outlined'
              size='large'
              color='default'
              disabled={isExpired}
              onClick={onAccept}
              className={[classes.buttonAccept, classes.buttonStyle].join(' ')}
              startIcon={<Check />}>
              Accept
            </Button>
            <Button
              variant='outlined'
              size='large'
              color='default'
              disabled={isExpired}
              onClick={onDecline}
              className={[classes.buttonDecline, classes.buttonStyle].join(' ')}
              startIcon={<Clear />}>
              Reject
            </Button>
          </Grid>
        </CardActions>
      );
    }

    return null;
  };

  const onCancel = () => {
    setShowCounterofferForm(false);
  };

  const getCounterOfferForm = () => {
    if (isExpired || clientSelection !== 0) {
      return null;
    }
    if (showCounterofferForm) {
      switch (paymentType) {
        case 1: return <LeaseForm onCounterOffer={onCounterOffer} onCancel={onCancel} />;
        case 2: return isTradeIn ? <FinanceTradeInForm onCounterOffer={onCounterOffer} onCancel={onCancel} /> :
          <FinanceForm onCounterOffer={onCounterOffer} onCancel={onCancel} />;
        case 3: return isTradeIn ? <CashTradeInForm onCounterOffer={onCounterOffer} onCancel={onCancel} /> :
          <CashForm onCounterOffer={onCounterOffer} onCancel={onCancel} />;
        default:
          return null;
      }

    } else {
      return (
        <CardActions>
          <Grid container
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={0}>
            <Button
              type='submit'
              variant='outlined'
              size='large'
              color='default'
              className={[classes.buttonCounterOffer, classes.buttonStyle, classes.buttonCounterOfferLarge].join(' ')}
              onClick={() => setShowCounterofferForm(true)}
              startIcon={<ShareIcon />}>
              Counter Offer
            </Button>
          </Grid>

        </CardActions>
      );
    }
  };

  const getHeaderMessage = () => {
    if (isExpired || clientSelection === 4) {
      return (
        <Typography align='center' className={classes.text} gutterBottom variant='h6'>
          Your offer has expired.
        </Typography>
      );
    }

    switch (clientSelection) {
      case 1:
        return (
          <Typography align='center' className={classes.text} gutterBottom variant='h6'>
            You`ve accepted the offer.<br />
            Our team will contact you soon.
          </Typography>
        );
      case 2:
        return (
          <Typography align='center' className={classes.text} gutterBottom variant='h6'>
            You`ve rejected the offer.
          </Typography>
        );
      case 3:
        return (
          <Typography align='center' className={classes.text} gutterBottom variant='h6'>
            Your counter offer has been received.<br />
            You will be contacted shortly.
          </Typography>
        );
      case 5:
        return (
          <Typography align='center' className={classes.text} gutterBottom variant='h6'>
            Your counter offer has been accepted.
          </Typography>
        );
      default:
        return (
          <Typography className={classes.text} gutterBottom variant='h6' >
            Dear {clientName},<br /> here is your offer:
          </Typography>
        );
    }
  };

  return (
    <Card className={classes.root}>
      <Grid container
        direction='row'
        justifyContent='center'
        alignItems='center'>
        <Grid item xs={12}>
          <Typography align='center' gutterBottom variant='h5'>
            <span className={classes.headCompName}>{companyName}</span>
          </Typography>
        </Grid>
        <Grid item xs={12} mb={12}>
          {getHeaderMessage()}
        </Grid>
        <CardMedia
          component='img'
          className={classes.media}
          image={clientSelection === 3 ? gifCounteredUrl : gifUrl}
          title='Offer' />
      </Grid>
      {disclaimer &&
        <Typography className={classes.text} variant='h6'>
          Disclaimer: <br />
          {disclaimer}
        </Typography>
      }
      <CardContent>
        {(expireAt && !clientSelection) && <Countdown date={expireAt} renderer={countdownRenderer} />}
        {getCardButtons()}
        {getCounterOfferForm()}
        {(!showCounterofferForm || clientSelection === 3 || clientSelection === 5) &&
          <Typography variant='h6' align='right' className={`${classes.text} ${classes.textRegards}`}>
            Best regards,
            <br />{salesName},
            <br />{companyName}
          </Typography>}
      </CardContent>
    </Card>
  );
};

OfferCard.propTypes = {
  paymentType: PropTypes.number,
  isTradeIn: PropTypes.bool,
  gifUrl: PropTypes.string,
  gifCounteredUrl: PropTypes.string,
  expireAt: PropTypes.number,
  clientName: PropTypes.string,
  salesName: PropTypes.string,
  companyName: PropTypes.string,
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
  onCounterOffer: PropTypes.func,
  clientSelection: PropTypes.number,
  isCounterOfferSelected: PropTypes.bool,
  disclaimer: PropTypes.string
};

export default OfferCard;
