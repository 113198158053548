import React from 'react';

import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { InputField, NoteInputField } from 'components';
import { formatNumber } from 'helpers';
import { useStyles } from '../style';
import FormButtons from './FormButtons';

const CashForm = ({ onCounterOffer, onCancel }) => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'error']);

  const validationSchema = Yup.object().shape({
    price: Yup.number()
      .required(t('required'))
      .min(0, t('priceMinValidation'))
      .max(1000000000, t('priceMaxValidation'))
      .typeError(t('pleaseEnterNumber')),
    internalNotes: Yup.string()
      .max(1000, t('notesValidation'))
  });

  return (
    <Formik
      initialValues={{
        price: undefined,
        internalNotes: undefined,
      }}
      validationSchema={validationSchema}
      onSubmit={onCounterOffer}>
      {({ isSubmitting, values, setFieldValue, dirty }) => (
        <Form className={classes.form}>
          <Grid container
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={0}>
            <p className={classes.formHeader}>Provide your Counter offer details:</p>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <InputField label='price$' inputName='price' type='text' defaultValue={values.price}
                isNumber isMoney isRequired
                className={classes.inputField}
                onChange={(e) => {
                  setFieldValue('price', formatNumber(e.target.value));
                }} />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <NoteInputField label='notes' inputName='internalNotes' type='text' className={classes.textareaField} />
            </Grid>
          </Grid>
          <FormButtons disabled={isSubmitting || !dirty} onCancel={onCancel} />
        </Form>
      )}
    </Formik>
  );
};

CashForm.propTypes = {
  onCounterOffer: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CashForm;
