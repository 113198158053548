import { makeStyles } from '@material-ui/core/styles';
import { displayBorder } from 'helpers';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    paddingLeft: '0.5rem',
    paddingBottom: '0.5rem',
    position: 'relative'
  },
  form: {
    width: '100%'
  },
  header: {
    fontSize: '1.5rem',
    letterSpacing: 0,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(displayBorder)]: {
      marginBottom: 0
    },
  },
  leadActions: {
    marginTop: '5%',
    placeContent: 'end',
    [theme.breakpoints.down(displayBorder)]: {
      placeContent: 'space-around',
      flexWrap: 'nowrap'
    },
  },
  sectionTitle: {
    marginBottom: '2%'
  },
  modal: {
    height: 'fit-content',
    minHeight: 'calc(100vh - 9.375rem)',
    padding: '2.5rem',
    backgroundClip: 'content-box',
    boxShadow: 'inset 0 0 0 2.5rem' + theme.quinaryBackgroundColor,
    margin: '0 auto',
    maxWidth: '100%'
  },
  paymentTypeContainer: {
    alignItems: 'center',
    marginBottom: '2rem',
  },
  selectPaymentType: {
    color: theme.primaryTextColor
  },
  iconStyle: {
    height: '1.5rem',
    width: '1.5rem',
    color: theme.primaryBackgroundColor,
    paddingLeft: '0,'
  },
  uncheckedIconStyle: {
    height: '1.5rem',
    width: '1.5rem',
    color: theme.primaryBorderColor,
    paddingLeft: '0,'
  },
  isRequired: {
    marginRight: '1rem',
    color: theme.primaryBackgroundColor
  },
  price: {
    padding: '0.5rem',
    marginTop: '0.5rem'
  },
  padding: {
    padding: '0.5rem',
  },
  '@media screen and (max-width:615px)': {
    modal: {
      boxShadow: 'inset 0 0 0 0',
      padding: '1rem'
    },
    padding: {
      padding: '0'
    },
    radio: {
      flexWrap: 'nowrap'
    },
    header: {
      marginBottom: '1rem'
    },
    price: {
      padding: '0'
    }
  }
}));
