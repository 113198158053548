import React from 'react';

import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { InputField, NoteInputField, FormSelectFilter } from 'components';
import { formatNumber, arrayOfTerms } from 'helpers';
import { useStyles } from '../style';
import FormButtons from './FormButtons';

const FinanceForm = ({ onCounterOffer, onCancel }) => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'error']);

  const validationSchema = Yup.object().shape({
    price: Yup.number()
      .required(t('required'))
      .min(0, t('priceMinValidation'))
      .max(1000000000, t('priceMaxValidation'))
      .typeError(t('pleaseEnterNumber')),
    term: Yup.number()
      .required(t('required'))
      .min(0, t('termMinValidation'))
      .max(1000000000, t('termMaxValidation'))
      .typeError(t('pleaseEnterNumber')),
    rate: Yup.number()
      .required(t('required'))
      .min(0, t('rateMinValidation'))
      .max(1000000000, t('rateMaxValidation'))
      .typeError(t('pleaseEnterNumber')),
    downPayment: Yup.number()
      .required(t('required'))
      .min(0, t('downPaymentMinValidation'))
      .max(1000000000, t('downPaymentMaxValidation'))
      .typeError(t('pleaseEnterNumber')),
    payment: Yup.number()
      .required(t('required'))
      .min(0, t('monthlyPaymentMinValidation'))
      .max(1000000000, t('monthlyPaymentMaxValidation'))
      .typeError(t('pleaseEnterNumber')),
    internalNotes: Yup.string()
      .max(1000, t('notesValidation'))
  });

  return (
    <Formik
      initialValues={{
        price: undefined,
        term: undefined,
        rate: undefined,
        downPayment: undefined,
        payment: undefined,
        internalNotes: undefined,
      }}
      validationSchema={validationSchema}
      onSubmit={onCounterOffer}>
      {({ isSubmitting, values, setFieldValue, dirty }) => (
        <Form className={classes.form}>
          <Grid container
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={0}>
            <p className={classes.formHeader}>Provide your Counter offer details:</p>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <InputField label='price$' inputName='price' type='text' defaultValue={values.price}
                isNumber isMoney isRequired
                className={classes.inputField}
                onChange={(e) => {
                  setFieldValue('price', formatNumber(e.target.value));
                }} />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <FormSelectFilter
                isRequired
                label={t('termFinance')}
                className={classes.selectField}
                onChange={(e) => {
                  setFieldValue('term', Number(e.target.value));
                }}
                value={values.term}
                menuItem={arrayOfTerms} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField label='rate%' inputName='rate' type='text' defaultValue={values.rate}
                isNumber isRequired
                className={classes.inputField}
                onChange={(e) => {
                  setFieldValue('rate', formatNumber(e.target.value));
                }} />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <InputField label='downPayment$' inputName='downPayment' type='text' defaultValue={values.downPayment}
                isNumber isMoney isRequired
                className={classes.inputField}
                onChange={(e) => {
                  setFieldValue('downPayment', formatNumber(e.target.value));
                }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField label='payment$' inputName='payment' type='text' defaultValue={values.payment}
                isNumber isMoney isRequired
                className={classes.inputField}
                onChange={(e) => {
                  setFieldValue('payment', formatNumber(e.target.value));
                }} />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <NoteInputField label='notes' inputName='internalNotes' type='text' className={classes.textareaField} />
            </Grid>
          </Grid>
          <FormButtons disabled={isSubmitting || !dirty} onCancel={onCancel} />
        </Form>
      )}
    </Formik>
  );
};

FinanceForm.propTypes = {
  onCounterOffer: PropTypes.func,
  onCancel: PropTypes.func,
};

export default FinanceForm;
