import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: '0.6rem',
    display: 'flex',
    borderRadius: '15rem',
    height: '2.5rem',
    width: '100%',
    justifyContent: 'space-between',
    backgroundColor: theme.quaternaryBackgroundColor,
    padding: '0.3rem',
    boxShadow: 'rgb(152, 160, 206, 0.2) 0px 3px 7px 0px',
  },
  childContainer: {
    flexWrap: 'nowrap',
    marginLeft: '0.5rem'
  },
  input: {
    color: theme.quaternaryTextColor,
    marginLeft: '0.2rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    border: '0',
    outline: 'none',
    top: '0.6rem',
    height: '2rem',
    position: 'relative',
    backgroundColor: theme.transparent,
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px #F2F4F7 inset',
      fontSize: '1rem'
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: theme.quaternaryBackgroundColor
    },
    fontFamily: theme.fontLato,
    width: '100%',
    fontSize: '1rem',
    whiteSpace: 'nowrap'
  },
  inputContainer: {
    width: '90%'
  },
  selectIcon: {
    color: theme.primaryTextColor,
    paddingRight: '0.5rem',
    marginLeft: '0.5rem'
  },
  selectMenu: {
    color: theme.quaternaryTextColor
  },
  formControl: {
    width: '100%'
  },
  labelContainer: {
    flexDirection: 'row'
  },
  label: {
    outline: 'none',
    bottom: '0.5rem',
    position: 'relative',
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    color: theme.primaryTextColor,
    paddingTop: '1rem'
  },
  isRequired: {
    bottom: '0.5rem',
    position: 'relative',
    fontSize: '1rem',
    color: theme.primaryBackgroundColor,
    paddingTop: '1rem'
  },
  formContainer: {
    border: '1px solid; border-radius: 7px; height: 3.5rem;',
    borderColor: theme.primaryBorderColor,
    width: '100%',
    marginBottom: '1.5rem'
  },
  formInput: {
    color: theme.quaternaryTextColor,
    marginLeft: '0.2rem',
    paddingLeft: '0.5rem',
    border: '0',
    outline: 'none',
    top: '0.6rem',
    height: '2rem',
    position: 'relative',
    '& .MuiSelect-select:focus': {
      backgroundColor: theme.quaternaryBackgroundColor
    },
    width: '95%',
    fontSize: '1rem',
    whiteSpace: 'nowrap'
  },
  iconSelectContainer: {
    border: '1px solid; border-radius: 7px; height: 3rem;',
    borderColor: theme.primaryBorderColor,
    width: '100%'
  },
  img: {
    height: '0.9rem',
    width: '0.9rem',
    margin: '6px 0 0 0'
  },
  offerExpired: {
    '& > img': {
      margin: '5px 0 0 0'
    }
  },
  draft: {
    '& > img': {
      margin: '5px 0 0 0'
    }
  },
  imgContainer: {
    height: '1.5rem',
    width: '1.5rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    marginRight: '1rem'
  },
  root: {
    minWidth: '0.466rem'
  },
  select: {
    display: 'flex',
    alignItems: 'center'
  },
  paginationContainer: {
    paddingBottom: '0.6rem',
    display: 'flex',
    borderRadius: '0.313rem',
    height: '1.75rem',
    width: '3.813rem',
    justifyContent: 'space-between',
    backgroundColor: theme.quaternaryBackgroundColor,
    padding: '0.2rem',
    boxShadow: 'rgb(152, 160, 206, 0.2) 0px 3px 7px 0px',
  },
  paginationSelectMenu: {
    placeContent: 'center'
  },
  paginationSelectIcon: {
    left: '1.5rem'
  },
  paginationInput: {
    color: theme.quaternaryTextColor,
    marginLeft: '0.2rem',
    top: '0.2rem',
    paddingLeft: '0.5rem',
    border: '0',
    outline: 'none',
    height: '2rem',
    position: 'relative',
    backgroundColor: theme.transparent,
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px #F2F4F7 inset',
      fontSize: '1rem'
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: theme.quaternaryBackgroundColor
    },
    width: '85%',
    fontSize: '1rem',
    whiteSpace: 'nowrap'
  },
  wrap: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 1,
    boxOrient: 'vertical',
    wordWrap: 'break-word'
  },
  validation: {
    color: theme.validationTextColor,
    top: '1.4rem',
    position: 'relative',
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 'normal',
    backgroundColor: theme.validationBackgroundColor,
    width: '100%'
  },
  formSelectItem: {
    height: '2.5rem',
  }
}));